import { fadeOut } from './helpers'
import lozad from 'lozad'

document.addEventListener('turbo:load', () => {
  // submit form with key code 13 - Enter
  const searchable = document.querySelector('.js-searchable')
  if (searchable) {
    searchable.addEventListener('keydown', e => {
      let key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0
      if (key === 13) {
        e.currentTarget.closest('form').submit()
      }
    })
  }

  const fadeable = document.querySelector('.js-fadeable')
  if (fadeable) {
    setTimeout(() => {
      fadeOut(fadeable)
    }, 6000)
  }

  // lazy loads elements with default selector as '.lozad'
  // looks like data-toggle doesn't fit for blur class
  // it triggers when image enters the browser’s viewport
  window.observer = lozad('.lozad', {
    loaded: el => el.classList.remove('lozad-blur')
  })
  window.observer.observe()

  // TODO: make vanilla implementation of such code
  // enter, downarrow, rightarrow
  // on Enter giving focus to the next visible input
  // $('input, select').keydown( function(e) {
  //   var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0;
  //   if (key == 13) {
  //     if (!$(this).hasClass("js-searchable")) {
  //       console.log('here!')
  //       e.preventDefault();
  //       // all inputs that are visible
  //       var inputs = $(this).closest('form').find(':input:visible');
  //       // eq - reduce the set of matched elements to the one at the specified index
  //       // index - search for a given element from among the matched elements
  //       inputs.eq( inputs.index(this) + 1 ).focus();
  //     }
  //   }
  // });

})
