// function for import in stimulus controllers

export const fadeOut = (el, duration = 2000) => {
  el.style.opacity = 1
  el.style.transition = `opacity ${duration}ms`
  el.style.opacity = 0
  setTimeout(() => {
    el.style.display = 'none'
  }, duration)
}

// export function fadeIn(el, display) {
//   el.style.opacity = 0
//   el.style.display = display || "block"
//   (function fade() {
//     var val = parseFloat(el.style.opacity)
//     if (!((val += .1) > 1)) {
//       el.style.opacity = val
//       requestAnimationFrame(fade)
//     }
//   })()
// }

// to get array of element's parents
export function getParents(elem) {
  // Set up a parent array
  let parents = []
  // Push each parent element to the array
  while (elem.parentElement && elem.parentElement.tagName.toLowerCase() !== 'body') {
    parents.push(elem)
    elem = elem.parentElement
  }
  // Return parent array
  return parents
}

// generating hex instead of ruby's SecureRandom.hex(6)
export const genHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')
