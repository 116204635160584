import { Controller } from 'stimulus'
require("parsleyjs")
import '../../src/css/modules/parsley.scss'

export default class extends Controller {
  static targets = [ 'parsleyForm' ]

  initialize() {
    // mostly for finish_register and edit info in account
    $(this.parsleyFormTarget).parsley({
      // bootstrap error classes
      errorClass: 'is-invalid',
      successClass: 'is-valid',
      errorsWrapper: '<ul class="invalid-feedback"></ul>',
      errorTemplate: '<li></li>'
    })
  }

}
