import { Controller } from "stimulus"
import SlimSelect from 'slim-select'
import '../../src/css/modules/slim_select.scss'

export default class extends Controller {

  connect() {
    this.select = new SlimSelect({
      select: this.element,
      placeholder: this.data.get('placeholder'),
      limit: this.data.get('limit'),
      // do not focus on search fields for small devices
      searchFocus: window.innerWidth > 768,
      onChange: info => {
        if (this.data.get('dispatch') === 'true') {
          const event = new CustomEvent('select:onChange', {detail: info})
          window.dispatchEvent(event)
        }
      }
    })
  }

  disconnect() {
    this.select.destroy()
  }

}
