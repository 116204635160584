// here we will import all needed icons
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import {
  faGlobe,
  faMapMarkerAlt,
  faCity,
  faUserPlus,
  faLanguage,
  faPencilAlt,
  faTrashAlt,
  faHeart as fasFaHeart,
  faUser,
  faKey,
  faSignInAlt,
  faCheckDouble,
  faLock,
  faCloudUploadAlt,
  faEdit,
  faMusic,
  faMicrophoneAlt,
  faKeyboard,
  faPlusSquare,
  faAngleDown,
  faThList,
  faExclamationCircle,
  faDownload,
  faUsers,
  faArrowsAlt,
  faCrown,
  faChevronDown,
  faBars
} from '@fortawesome/free-solid-svg-icons'
import {
  faHeart as farFaHeart,
  faComments,
  faCopyright,
  faWindowClose,
  faClock,
  faBell,
} from '@fortawesome/free-regular-svg-icons'
import { faTwitterSquare, faYoutube, faVimeoV } from '@fortawesome/free-brands-svg-icons'

// We are only using the user-astronaut icon
library.add(
  faGlobe,
  faMapMarkerAlt,
  faCity,
  faUserPlus,
  faLanguage,
  faPencilAlt,
  faTrashAlt,
  fasFaHeart,
  farFaHeart,
  faComments,
  faTwitterSquare,
  faUser,
  faKey,
  faSignInAlt,
  faCheckDouble,
  faLock,
  faCloudUploadAlt,
  faEdit,
  faMusic,
  faMicrophoneAlt,
  faKeyboard,
  faCopyright,
  faYoutube,
  faVimeoV,
  faPlusSquare,
  faAngleDown,
  faThList,
  faWindowClose,
  faExclamationCircle,
  faClock,
  faDownload,
  faUsers,
  faArrowsAlt,
  faCrown,
  faBell,
  faChevronDown,
  faBars
)

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch()
