// better to keep content and destroy only if not the same

import { Controller } from 'stimulus'
import '../../src/css/modules/lightbox'
import '../../src/css/modules/spinner'

export default class extends Controller {
  static targets = [
    'lightbox',
    'content',
    'spinner',
    'closer',
  ]

  // action
  openModal(event) {
    this.lightboxTarget.classList.remove('d-none')
    document.body.style.overflow = 'hidden'
    const id = event.currentTarget.dataset.id
    const type = event.currentTarget.dataset.type
    const mimetype = event.currentTarget.dataset.mimetype
    if (mimetype && mimetype.includes('image')) {
      let img = document.createElement('img')
      img.src = event.currentTarget.src
      img.classList.add('lighbox__image')
      this.contentTarget.append(img)
      this.spinnerTarget.classList.add('d-none')
    } else {
      fetch(`/movies/${id}/lightbox?type=${type}`)
        .then(response => response.json())
        .then(data => {
          this.contentTarget.innerHTML = data.html
          this.spinnerTarget.classList.add('d-none')
        })
    }
  }

  closeModal(event) {
    if (event.target === this.lightboxTarget || event.currentTarget === this.closerTarget) {
      // better to use IntersectionObserver in plyr_controller
      // to detect if parent element became invisible
      this.contentTarget.innerHTML = null
      this.lightboxTarget.classList.add('d-none')
      document.body.style.overflow = 'visible'
      this.spinnerTarget.classList.remove('d-none')
    }
  }

}
