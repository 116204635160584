import { Controller } from 'stimulus'
import iziToast from 'izitoast'
import '../../src/css/modules/izitoast.scss'

export default class extends Controller {
  static targets = ['message']

  connect() {
    if (this.hasMessageTarget) {
      this.messageTargets.forEach(() => {
        this.showToast()
      })
    }
  }

  // example of custom icon:
  // icon: 'fas fa-exclamation-circle font-awesome-opacity'
  // font-awesome-opacity - fixing opacity 0 bug

  toast(mes = this.data.get('message'), type = this.data.get('type')) {
    const options = {
      message: mes,
      position: 'topRight',
      timeout: 6000,
    }
    switch(type) {
      // most common rails flash message
      case 'notice':
        iziToast.success(options)
        break
      case 'success':
        iziToast.success(options)
        break
      case 'warning':
        iziToast.warning(options)
        break
      // error flash message
      case 'alert':
        iziToast.error(options)
        break
      case 'error':
        iziToast.error(options)
        break
      case 'info':
        iziToast.info(options)
        break
      default:
        iziToast.info(options)
    }
  }

  // action listen to the custom event 'toast:show'
  // with the message in detail
  show(event) {
    this.toast(event.detail.message, event.detail.type)
  }

  // action
  showToast() {
    this.toast(this.data.get('message'))
  }

}
