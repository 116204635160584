import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'state', 'city', 'cityInput' ]

  // action from custom event
  showStates(event) {
    console.log(event.detail)
    this.cityInputTarget.value = null
    if (event.detail.value && event.detail.value !== '') {
      fetch(`/get_states?country=${event.detail.value}&obj=user`)// obj temporary hardcoded
        .then(res => res.json())
        .then(data => {
          this.stateTarget.innerHTML = data.html
          this.cityTarget.classList.remove('d-none')
        })
        .catch(err => console.error('we got an error: ', err))
    } else {
      this.stateTarget.classList.add('d-none')
      this.stateTarget.innerHTML = null
      this.cityTarget.classList.add('d-none')
    }
  }

}
