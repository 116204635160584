/* eslint no-console:0 */

import Rails from "@rails/ujs" // on home page needed for method: :delete
import "@hotwired/turbo-rails"
window.$ = window.jQuery = require('jquery') // to expose $ to global namespace
Rails.start()

// stimulus
import 'controllers/general'

// bootstrap 4
import * as bootstrap from 'bootstrap'
// easy but not so good way to get Modal
// in stimulus controller w/o import
window.Modal = bootstrap.Modal
window.bootstrap = bootstrap

// imported fontawesome icons
import 'src/js/fontawesome_imports'

import 'src/js/general'

const requireGeneral = require.context('src/css/general', false, /\.(css|scss|sass)$/)
requireGeneral.keys().forEach(requireGeneral)
